import React from 'react'
import { Link } from 'react-router-dom';
import CardItem from './CardItem';
import './Cards.css';
import { Button } from './button';

function Cards() {
    return (
        <div className='full-gallery-container'>
        <div className='gallery-container-1'>
            <div className='cards-full-wrapper-1'>
                <div className='mobile-gallery-1'>
                <div className="row">
                <img src={process.env.PUBLIC_URL + "/images/processed (7).jpeg"} alt="Gallery Item" />
                </div>
                </div>
            <div className="gallery-1">
            <div className="row">
                     <img src="/images/processed (7).webp" alt="Gallery Item" />
                    <img src="/images/processed (20).webp" alt="Gallery Item" />
                </div>
                <div className="row">
                    <img src="/images/processed (8).webp" alt="Gallery Item" />
                    <img src="/images/processed (37).webp" alt="Gallery Item" />
                    <img src="/images/processed (15).webp" alt="Gallery Item" />
                </div>
            </div>
            <div className="gallery-text-1">
                <h2 className='cards-title'>Explore Balgonie Retreat</h2>
                <p className='cards-subtext'>Balgonie Retreat offers a serene haven from urban life or an ideal starting point for engaging in the region's diverse, all-season adventures</p>
                <div className='book-button-container'>
                <Link to='/services' className='booking-button'>View Gallery</Link>
          </div>
            </div>
            </div>
        </div>
         <div className='gallery-container-2'>
         <div className='cards-full-wrapper-2'>
         <div className='mobile-gallery-2'>
                <div className="row">
                <img src={process.env.PUBLIC_URL + "/improved-images/fringe.jpeg"} alt="Gallery Item" />
                </div>
        </div>
         <div className="gallery-text-2">
             <h2 className='cards-title'>Things to do</h2>
             <p className='cards-subtext'>Situated near the gateway to the scenic Pentland Hills, West Linton offers access to outdoor pursuits, close proximity to captivating cultural sites, and the beauty of several local nature reserves</p>
             <div className='book-button-container'>
             <Link to='/stay' className='booking-button'>Learn More</Link>
       </div>
         </div>
         <div className="gallery-2">
             <div className="row">
             <img src="/improved-images/hillend.jpeg" alt="Gallery Item" />
                 <img src="/improved-images/stobo.jpeg" alt="Gallery Item" />
             </div>
             <div className="row">
                 <img src="/improved-images/pentlands.jpeg" alt="Gallery Item" />
                 <img src="/improved-images/fringe.jpeg" alt="Gallery Item" />
             </div>
         </div>
         </div>
     </div>
     <div className='mobile-gallery-3'>
                <div className="row">
                <img src="/images/processed (14).jpeg" alt="Gallery Item" />
                </div>
                </div>
     <div className='gallery-container-3'>
     <div className="gallery-text">
                <h2 className='cards-title'>Your holiday awaits...</h2>
                <p className='cards-subtext'>Experience the perfect holiday retreat now!</p>
                <div className='book-button-container'>
          <a href="https://www.holidaycottages.co.uk/cottage/81467-balgonie-retreat" className="booking-button" target="_blank" rel="noopener noreferrer">Book Now</a>
          </div>
            </div>
     </div>
     </div>
        
      );
}

export default Cards