import About from "../About";
import GalleryTest from "../GalleryTest";

export default function Products() {
  return (
    <div className='products'>
     <About />
    </div>
  );
}
