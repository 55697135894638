import React from 'react';
import './Attractions.css'; // Ensure to create this CSS file
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';


const attractions = [
  {
    name: "West Linton Village",
    description: "A charming village with historical sites and a quaint atmosphere, perfect for leisurely exploration.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/west-linton.jpeg",
    url: "https://www.visitscotland.com/info/towns-villages/west-linton-p242371"
  },
  {
    name: "Pentland Hills",
    description: "Breathtaking natural beauty ideal for hiking, photography, and outdoor adventures.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/pentlands(1).jpeg",
    url: "https://www.pentlandhills.org"
  },
  {
    name: "West Linton Golf Club",
    description: "Offering a beautiful golfing experience amidst well-maintained greens and scenic surroundings.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/golf.jpeg",
    url: "https://www.wlgc.co.uk"
  },
  {
    name: "Horse Riding Adventures",
    description: "Explore the breathtaking landscapes of Peebles on horseback, suitable for all skill levels.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/horseriding.jpeg",
    url: "https://kailzieequestrianltd.com"
  },
  {
    name: "Edinburgh Fringe Festival",
    description: "Immerse yourself in the world’s largest arts festival, featuring thousands of performers in Scotland's capital.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/fringe.jpeg",
    url: "https://www.edfringe.com"
  },
  {
    name: "Glentress",
    description: "Award-winning mountain biking trails amidst stunning forest scenery, offering a blend of thrilling paths and picturesque views.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/glentress.jpeg",
    url: "https://forestryandland.gov.scot/visit/forest-parks/tweed-valley-forest-park/glentress"
  },
  {
    name: "Stobo Castle",
    description: "A luxurious retreat set in a historic castle, offering spa experiences and exquisite gardens for the ultimate relaxation experience.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/stobo.jpeg",
    url: "https://www.stobocastle.co.uk"
  },
  {
    name: "Go Ape",
    description: "An exhilarating treetop adventure with zip lines, rope bridges, and swings, set in a beautiful forest environment.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/goape.jpeg",
    url: "https://goape.co.uk/locations/glentress-forest"
  },
  {
    name: "Hillend Ski Slope",
    description: "The UK's longest artificial ski slope offering a unique skiing and snowboarding experience with stunning views.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/hillend.jpeg",
    url: "https://www.midlothian.gov.uk/info/200281/snowsports_centre"
  },
  {
    name: "Gordon Arms",
    description: "A cosy and traditional pub in West Linton, known for its warm hospitality and delicious local cuisine.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/gordanarms.jpeg",
    url: "https://www.thegordon.co.uk"
  },
  {
    name: "Gillespsie's Family Butchers",
    description: "A renowned local butcher offering a selection of premium meats and homemade delicacies.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/butchers.jpeg",
    url: "https://www.gillespiesgoodfood.co.uk/#"
  },
  {
    name: "The Pyet",
    description: "Discover the charm of West Linton at The Pyet, a cosy haven offering an array locally-sourced delights and freshly baked goods.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/pyetinside.jpeg",
    url: "https://thepyet.co.uk"
  },
  {
    name: "Whitmuir",
    description: "A delightful destination near West Linton, featuring organic farming, a charming farm shop, and engaging educational tours.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/whitmuir.jpeg",
    url: "https://whitmuir.uk/"
  },
  {
    name: "Horseshoe Inn",
    description: "An idyllic country inn in Eddleston offering exquisite dining, comfortable accommodations, and a charming atmosphere.",
    imageUrl: process.env.PUBLIC_URL + "/improved-images/horseshoeinn.jpeg",
    url: "https://horseshoeinn.co.uk"
  },
];

const attractionVariants = {
  visible: { opacity: 1, translateY: 0, transition: { duration: 0.5 } },
  hidden: { opacity: 0, translateY: 50 },
};

function AttractionCard({ attraction }) {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0,
  });

  return (
    <motion.div 
      ref={ref}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={attractionVariants}
      className="attraction-card"
    >
       <a href={attraction.url} target="_blank" rel="noopener noreferrer" className="attraction-link">
       <img src={attraction.imageUrl} alt={attraction.name} />
       <FontAwesomeIcon icon={faExternalLinkAlt} className="external-link-icon" />
      </a>
      <div className="attraction-info">
        <h3>{attraction.name}</h3>
        <p>{attraction.description}</p>
      </div>
    </motion.div>
  );
}

function Attractions() {
  return (
    <div className="attractions-section">
      <h2 className='attractions-title'>Local Attractions</h2>
      <div className="attractions-grid">
        {attractions.map((attraction, index) => (
          <AttractionCard key={index} attraction={attraction} />
        ))}
      </div>
    </div>
  );
}


export default Attractions
