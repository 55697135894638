import '../../App.css';
import HomepageTop from '../HomepageTop';
import Cards from '../Cards';
import Overview from '../Overview';
import Testimonials from '../Testimonials';


function Home() {
  return (
    <>
      <HomepageTop />
      <Overview />
      <Cards />
     <Testimonials />
    </>
  );
}

export default Home;